<template>
  <div class="animated fadeIn container">
    <h1>통계</h1>
    <div class="month-picker-container">
      <span class="prev-btn" @click="changeMonth(1)">◀︎</span>
      <span class="year-month">{{ yearMonth }}</span>
      <span class="next-btn" @click="changeMonth(-1)">▶︎</span>
    </div>
    <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
    <div id="curve_chart" style="width:100%;height: 450px" v-show="!isLoading"></div>
    <table class="border-table mt-4" v-show="!isLoading" style="margin-bottom: 200px">
      <thead>
        <tr>
          <td style="text-align: center;">날짜</td>
          <td :key="provider.id" v-for="provider in providers" style="text-align: center;">{{ provider.title }}</td>
          <td style="text-align: center;">Total</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="i" v-for="(item, i) in items">
          <td style="text-align:center">
            {{ getDate(item) }}
          </td>
          <td :key="j" v-for="(subItem, j) in item.slice(1)" class="data-col">
            <span>{{ subItem | numberFormat }}</span>
          </td>
          <td class="data-col">
            <span>{{ sumSubItems(item) | numberFormat }}</span>
          </td>
        </tr>
        <tr class="last-row">
          <td style="text-align: center">Total</td>
          <td :key="i" v-for="(provider, i) in providers" class="data-col">
            <span>{{ totalCountInfo[provider.id] | numberFormat }}</span>
          </td>
          <td class="data-col">
            <span>{{ sumTotalCount() | numberFormat }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import StaticsService from '@/services/StaticsService'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import moment from 'moment'

export default {
  name: 'Statics',
  components: {
    PulseLoader,
  },
  head: {
    script: [
      { type: 'text/javascript', src: 'https://www.gstatic.com/charts/loader.js', async: false, body: false },
    ],
  },
  data() {
    return {
      items: [],
      date: moment(),
      yearMonth: moment().format('YYYY.MM'),
      providers: [],
      totalCountInfo: {},
      isLoading: false,
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    document.body.classList.add('bg-white')
  },
  methods: {
    async getInfo() {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      const response = await StaticsService.get({
        yearMonth: this.date.format('YYYY-MM'),
      })
      if (response.status !== 200) {
        return
      }
      const { list, providers } = response.data
      this.providers = providers

      let info = {}
      let totalCountInfo = {}
      for (const item of list) {
        if (!info[item.date]) {
          info[item.date] = {}
        }

        if (!totalCountInfo[item.providerId]) {
          totalCountInfo[item.providerId] = 0
        }

        totalCountInfo[item.providerId] += parseInt(item.totalCount)
        info[item.date][item.providerId] = parseInt(item.totalCount)
      }

      this.totalCountInfo = totalCountInfo

      var items = []
      for (const key in info) {
        const tempInfo = info[key]

        let item = [parseInt(key.substring(8))]
        for (const provider of providers) {
          item.push(tempInfo[provider.id] || 0)
        }
        items.push(item)
      }
      this.items = items
      this.isLoading = false
      window.google.charts.load('current', { packages: ['corechart'], language: 'kr' })
      window.google.charts.setOnLoadCallback(this.drawChart)
    },
    drawChart() {
      var data = new window.google.visualization.DataTable()
      data.addColumn('number', 'date')
      for (const provider of this.providers) {
        data.addColumn('number', provider.title)
      }

      data.addRows(this.items)

      var chart = new window.google.visualization.LineChart(document.getElementById('curve_chart'))
      chart.draw(data)
    },
    sumSubItems(subItems) {
      var totalCount = 0
      for (let i = 1; i < subItems.length; i++) {
        totalCount += subItems[i]
      }
      return totalCount
    },
    sumTotalCount() {
      let totalCount = 0
      for (const key in this.totalCountInfo) {
        totalCount += this.totalCountInfo[key]
      }
      return totalCount
    },
    getDate(item) {
      return this.yearMonth + '.' + parseInt(item[0]) < 10 ? '0' : '' + item[0]
    },
    changeMonth(offset) {
      this.date = this.date.subtract(offset, 'months')
      this.yearMonth = this.date.format('YYYY.MM')
      this.getInfo()
    },
  },
}
</script>
<style scoped>
.month-picker-container {
  display: block;
  text-align: center;
}

.month-picker-container>span {
  display: inline-block;
}

.year-month {
  font-size: 20px;
}

.prev-btn,
.next-btn {
  padding: 20px;
  margin: 20px;
  font-size: 15px;
  cursor: pointer;
}

.data-col>span {
  display: inline-block;
  min-width: 100px;
  text-align: right;
}

.last-row td {
  border-top: 1px solid rgb(128, 128, 128);
}
</style>
